.align-elements{
    display: inline;
    align-items: left;
}

.form-select{
    width: 15vmin;
}

.results-title{
    margin-left: 0;
}