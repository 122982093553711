.App {
  text-align: left;
  overflow: 'scroll';
}

.App-logo {
  height: 20vmin;
  position:absolute;
  z-index: 0;
  /*pointer-events: none;*/
}

@media only screen and (min-device-width: 485px) and (orientation: landscape){
  .language-select-title{
    max-width: 10vw;
  }
  .align-selection{
    display: flex;
    margin-top: 15vh;
  }
  
  .align-language-select{
    display: flex;
    margin-bottom: 5px;
  }
  
  .error-message{
    margin-top: 20vh;
    padding: 10vh;
    color: navy;
    font-size: 2vw;
    position: relative;
    float: left;
    margin-left: -60vh;
  }
  .article-title{
    text-align: left;
    align-content: left;
    justify-content: left;
    font-size: 2vw;
    word-break: break-word;
  }

  .article-views, .article-rank{
      margin-top: 13.5px;
      font-size: 1.5vw;
      word-break: break-word;
  }
  .date-small{
    width: 30vmin;
    margin-left: auto;
    margin-right: auto;
  }
  .results-select, .results-title{
    width: 35vmin;
    margin-left: -30vmin;
  }
  .form-control, .btn{
    border-radius: 0.05;
  }
  .container-fluid{
    position: relative;
    max-width: 200vmin;
    margin-top: 35px;
    margin-left: 1vmin;
    margin-bottom: 25px;
  }
}

@media only screen and (max-device-width: 480px) and (orientation: portrait){
  #home-navigation{
    word-break: break-word;
    text-overflow: wrap;
  }
  .align-selection{
    display: inline-block;
    max-width: device-width;
  }
  .form-select{
    max-width: 115px;
    margin-left: -8vw;
  }
  .date-small{
    margin-top: 3.5vh;
    max-width: 160px;
    margin-left: -5vw;
  }
  .search-term{
    min-width: 50px;
    max-width: 115px;
  }
  .align-elements{
    max-width: 115px;
  }
  .date-title{
    margin-bottom: 0;
  }
  .article-title, .article-views, .article-rank{
    font-size: 3.5vw;
    word-break: break-word;
  }
  .container-fluid{
    position: relative;
    margin-left: 0;
    width: inherit;
    margin-top: 100px;
    margin-bottom: 25px;
    z-index: 1;
  }

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {
  background-color: #909fba;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.align-selection{
  display: flex;
  margin-top: 25vh;
  width: 100%;
}

.align-language-select{
  display: flex;
  margin-bottom: 5px;
  margin-top: 5vh;
}

.error-message{
  margin-top: 20vh;
  padding: 10vh;
  color: navy;
  font-size: 2vw;
  position: relative;
  float: left;
  margin-left: -60vh;
}
