.row-size{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    width: 100%;
    height: inherit;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.row-inner{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(150, 228, 188);
    color: black;
    width: 100%;
    height: inherit;
    margin-left: auto;
    margin-right: auto;
}

.article-title{
    text-align: left;
    align-content: left;
    justify-content: left;
    font-size: 2vw;
    word-break: break-word;
}

.article-views, .article-rank{
    margin-top: 13.5px;
    font-size: 1.5vw;
}

.article-views{
    text-align: right;
    align-content: right;
    justify-content: right;
}

.article-rank, .article-category{
    text-align: left;
    align-content: left;
    justify-content: left;
}

.container-fluid{
    width: inherit;
    margin-top: 100px;
    margin-bottom: 25px;
    z-index: 1;
}

.container-grid{
    display: relative;
    align-items: left;
}