.date-small{
    width: 40vmin;
    padding-left: 10px;
    padding-right: 10px;
}

.date-title{
    margin-left: 10px;
}

.align-elements{
    display: inline;
}